<template>
  <b-card v-if="classes" no-body class="card-company-table">
    <b-table :items="classes" responsive :fields="fields" class="mb-0">
      <!-- Id -->
      <template #cell(id)="data">
        <span class="font-weight-bolder mb-12">#{{ data.index + 1 }}</span>
      </template>

      <!-- CreatedAt Time -->
      <template #cell(createdAt)="data">
        <span class="font-weight-bolder mb-12">{{
          data.item.createdAt | moment("DD.MM.YYYY hh:mm")
        }}</span>
      </template>

      <!-- Status -->
      <template #cell(isActive)="data">
        <b-badge
          pill
          :variant="data.item.isActive ? 'light-success' : 'light-danger'"
        >
          {{ data.item.isActive ? "AKTİF" : "PASİF" }}
        </b-badge>
      </template>

      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            color="#3DB2FF"
            icon="MoreHorizontalIcon"
            size="16"
            class="mx-1"
            @click="data.toggleDetails"
          />
          <feather-icon
            color="#FFB830"
            icon="EditIcon"
            size="16"
            class="mx-1"
            @click="editClass(data.item)"
          />
          <feather-icon
            color="#80ED99"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="openDetails(data.item)"
          />
        </div>
      </template>

      <template #row-details="data">
        <b-table
          :items="getChildClass(data.item.id)"
          responsive
          :fields="subFiels"
          class="mb-0"
        >
          <!-- Id -->
          <template #cell(id)="data">
            <span class="font-weight-bolder mb-12">#{{ data.index + 1 }}</span>
          </template>

          <!-- CreatedAt Time -->
          <template #cell(createdAt)="data">
            <span class="font-weight-bolder mb-12">{{
              data.item.createdAt | moment("DD.MM.YYYY hh:mm")
            }}</span>
          </template>
          <!-- Status -->
          <template #cell(isActive)="data">
            <b-badge
              pill
              :variant="data.item.isActive ? 'light-success' : 'light-danger'"
            >
              {{ data.item.isActive ? "AKTİF" : "PASİF" }}
            </b-badge>
          </template>

          <template #cell(actions)="data">
            <div class="text-nowrap">
              <feather-icon
                color="#FFB830"
                icon="EditIcon"
                size="16"
                class="mx-1"
                @click="editClass(data.item)"
              />
            </div>
          </template>
        </b-table>
      </template>
    </b-table>
    <!--Open Add Branch-->
    <b-sidebar
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      v-model="addPanel"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">Yeni Sınıf Ekle</h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Body -->
        <b-form class="p-2" @submit.prevent>
          <b-form-group
            label="Eklemek İstediğiniz Sınıf"
            label-for="vue-select"
          >
            <v-select
              id="vue-select"
              v-model="addClassRequest.parentClassId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :reduce="(clas) => clas.id"
              label="name"
              :options="classes"
            />
          </b-form-group>
          <!-- Code -->
          <b-form-group label="Kodu" label-for="from">
            <b-form-input
              id="from"
              type="text"
              v-model="addClassRequest.code"
            />
          </b-form-group>

          <!-- Name -->
          <b-form-group label="Adı" label-for="to">
            <b-form-input
              id="to"
              trim
              type="text"
              v-model="addClassRequest.name"
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              @click="addBranch"
            >
              Ekle
            </b-button>
            <b-button variant="outline-secondary" @click="hide">
              Vazgeç
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>

    <b-sidebar
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      v-model="editPanel"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">Sınıf Güncelle</h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Body -->
        <b-form class="p-2" @submit.prevent>
          <b-form-group
            label="Eklemek İstediğiniz Sınıf"
            label-for="vue-select"
          >
            <v-select
              id="vue-select"
              v-model="editClassRequest.parentClassId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :reduce="(clas) => clas.id"
              label="name"
              :options="classes"
            />
          </b-form-group>
          <!-- Code -->
          <b-form-group label="Kodu" label-for="from">
            <b-form-input
              id="from"
              type="text"
              v-model="editClassRequest.code"
            />
          </b-form-group>

          <!-- Name -->
          <b-form-group label="Adı" label-for="to">
            <b-form-input
              id="to"
              trim
              type="text"
              v-model="editClassRequest.name"
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              @click="updateClass"
            >
              Güncelle
            </b-button>
            <b-button variant="outline-secondary" @click="hide">
              Vazgeç
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>

    <b-sidebar
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      v-model="detailsPanel"
    >
      <div class="card-transaction px-2 py-1">
        <div class="transaction-item">
          <b-media no-body>
            <b-media-body>
              <h6 class="transaction-title">Ders Listesi</h6>
              <small>Sınıfa Atanmış Dersler </small>
            </b-media-body>
          </b-media>
          <div class="font-weight-bolder text-danger"></div>
        </div>
        <div
          class="transaction-item"
          v-for="detail in details"
          :key="detail.id"
        >
          <b-media no-body>
            <b-media-aside>
              <b-avatar rounded size="42" variant="light-success">
                <feather-icon size="18" icon="PlusIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">{{ detail.name }}</h6>
              <small>{{ detail.code }}</small>
            </b-media-body>
          </b-media>
          <div class="font-weight-bolder text-danger"></div>
        </div>
      </div>
    </b-sidebar>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BImg,
  BBadge,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BMediaBody,
  BMedia,
  BMediaAside,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
    ToastificationContent,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
  },
  data() {
    return {
      fields: [
        { key: "id", label: "Ref" },
        { key: "createdAt", label: "Kayıt Tarihi" },
        { key: "isActive", label: "Durum" },
        { key: "code", label: "Kodu" },
        { key: "name", label: "Adı" },
        { key: "actions", label: "İşlemler" },
      ],
      subFiels: [
        { key: "id", label: "Ref" },
        { key: "createdAt", label: "Kayıt Tarihi" },
        { key: "isActive", label: "Durum" },
        { key: "code", label: "Kodu" },
        { key: "name", label: "Adı" },
        { key: "actions", label: "İşlemler" },
      ],
      classes: [],
      childClasses: [],
      addPanel: false,
      addClassRequest: {
        parentClassId: "",
        code: "",
        name: "",
      },
      editClassRequest: {
        id: "",
        parentClassId: "",
        code: "",
        name: "",
      },
      editPanel: false,
      detailsPanel: false,
      details: [],
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    this.$root.$on("AddButtonClick", () => {
      //console.log("Add Button Call");
      this.addPanel = true;
    });
  },
  methods: {
    async openDetails(data) {
      this.details = [];
      this.detailsPanel = true;
      var lessons = await this.$http.get("Branch/LessonClass/" + data.id);
      this.details = lessons.data.data;
    },
    editClass(data) {
      this.editClassRequest.id = data.id;
      this.editClassRequest.code = data.code;
      this.editClassRequest.name = data.name;
      if (data.parentClass != null) {
        this.editClassRequest.parentClassId = data.parentClass.id;
      } else {
        this.editClassRequest.parentClassId = null;
      }
      this.editPanel = true;
    },
    getChildClass(id) {
      return this.childClasses.filter((x) => x.parentClass.id == id);
    },
    async getData() {
      var classes = await this.$http.get("Branch/Classes");
      this.classes = classes.data.data;
      var childClasses = await this.$http.get("Branch/ChildClasses");
      this.childClasses = childClasses.data.data;
    },
    async addBranch() {
      if (this.addClassRequest.parentClassId == "")
        this.addClassRequest.parentClassId =
          "00000000-0000-0000-0000-000000000000";
      await this.$http.post("Branch/AddClass", this.addClassRequest);
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Bildirim",
          icon: "BellIcon",
          text: "İşlem Başarılı.",
          variant: "success",
        },
      });
      this.addPanel = false;
      this.getData();
    },
    async updateClass() {
      if (this.editClassRequest.parentClassId == this.editClassRequest.id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Bildirim",
            icon: "BellIcon",
            text: "Hatalı İşlem.",
            variant: "error",
          },
        });
        return;
      }

      if (this.editClassRequest.parentClassId == null)
        this.editClassRequest.parentClassId =
          "00000000-0000-0000-0000-000000000000";
      await this.$http.put("Branch/UpdateClass", this.editClassRequest);
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Bildirim",
          icon: "BellIcon",
          text: "İşlem Başarılı.",
          variant: "success",
        },
      });
      this.editPanel = false;
      this.getData();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
